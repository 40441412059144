<template>
<!-- Q&A -->
    <div>
        <div class="qa computer">
            <div style="margin-bottom: 20px" v-for="(item, index) in qaList" :key="index">
                <div class="line"></div>
                <div class="asking">
                    <span class="label">Q</span>
                    <span class="content main_body">{{item.asking}}</span>
                </div>
                <div class="answer">
                    <span class="label">A</span>
                    <pre><span class="content main_body">{{item.answer}}</span></pre>
                </div>
            </div>
            <div style="display: flex;justify-content: center;width: 100%;">
                <el-button v-show="!ifQuiz" class="bt_r" size="medium" type="primary" @click="getQuiz" style="font-size: 26px;">{{node.askTranslate}}</el-button>
                <el-form v-show="ifQuiz" ref="ruleForm" :rules="rules" :model="formInline" class="demo-form-inline" style="width: 100%;">
                    <el-form-item label="" prop="question">
                        <el-input type="textarea" v-model="formInline.question" :autosize="{ minRows: 9, maxRows: 20}" :placeholder="node.fillQuestionTranslate"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="bt_r ml20 main_body" type="primary" @click="submit">{{node.submitTranslate}}</el-button>
                        <el-button class="bt_r main_body" type="primary" @click="cancel">{{node.cancelTranslate}}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="phone">
            <div class="phone_qa">
                <div style="margin-bottom: 20px" v-for="(item, index) in qaList" :key="index">
                    <div class="line"></div>
                    <div class="asking">
                        <span class="label">Q</span>
                        <span class="content">{{item.asking}}</span>
                    </div>
                    <div class="answer">
                        <span class="label">A</span>
                        <pre><span class="content">{{item.answer}}</span></pre>
                    </div>
                </div>
            </div>
            <div class="phone_form">
                <div v-show="!ifQuiz" style="display: flex;flex-direction: row-reverse;margin-top: 1vh;justify-content: center;">
                    <van-button type="primary" style="height: 4vh;border-radius: 0.6vh;background-color: #1989fa;border: 1px solid #1989fa;font-size: 2vh;padding: 0 1vh;color: #fff;" @click="getQuiz">{{node.askTranslate}}</van-button>
                </div>
                <van-form ref="vanForm" v-show="ifQuiz">
					<van-cell-group inset class="vanForm_question">
                        <van-field v-model="formInline.question" rows="5" autosize label="" type="textarea" :placeholder="node.fillQuestionTranslate"/>
					</van-cell-group>
                    <div class="phone_form_button">
                        <div>
                            <van-button type="primary" style="height: 4vh;border-radius: 0.6vh;background-color: #1989fa;border: 1px solid #1989fa;font-size: 2vh;padding: 0 1vh;color: #fff;" @click="submit">{{node.submitTranslate}}</van-button>
                            <van-button type="primary" style="height: 4vh;border-radius: 0.6vh;background-color: #1989fa;border: 1px solid #1989fa;font-size: 2vh;padding: 0 1vh;color: #fff;margin-left: 2vh" @click="cancel">{{node.cancelTranslate}}</van-button>
                        </div>
                    </div>
                </van-form>
            </div>
            <phone-menu></phone-menu>
        </div>
    </div>
</template>

<script>
import phoneMenu from '../../components/phoneMenu.vue'
import { Message } from 'element-ui';
import { sitequestionanswerList, sitequestionanswerSave } from '@/api/index'
export default {
    data() {
        return {
            qaList: [],
            rules: {
                question: [
                    { required: true, message: '请输入问题', trigger: 'change' }
                ],
            },
            ifQuiz: false,
            formInline: {}
        }
    },
    components: {
        phoneMenu
    },
    computed: {
        node(){
            return this.$store.state.node
        },
        languageType(){
            return this.$store.state.languageType
        },
    },
    mounted() {
        /* let maxResult = window.matchMedia('(max-width: 980px)')
        if (maxResult.matches) {
            var h =window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            let f = document.getElementsByClassName('phone_footer')[0].offsetHeight
            let he = document.getElementsByClassName('p_header')[0].offsetHeight
            document.getElementsByClassName('qa')[0].style.minHeight = (h - h - he - 2) + 'px'
        } */
        this.getList()
        this.getData()
        this.rules.question[0].message = this.getLanguageText(this.languageType, '请输入问题')
    },
    methods: {
        // 获取问答列表
        getList() {
            let params = {
                current: 1,
                size: 999,
                querydata: {
                    status: 3,
                    siteCode: this.$store.state.siteCode
                }
            }
            params.querydata = JSON.stringify(params.querydata)
            sitequestionanswerList(params).then((res) => {
                if (res && res.code == 0) {
                    this.qaList = []
                    res.data.records.map(item => {
                        this.qaList.push({
                            asking: item.question,
                            answer: item.answer
                        })
                    })
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        getData() {
            this.rules.question[0].message = this.node.fillQuestionTranslate
            this.$forceUpdate()
        },
        getQuiz () {
            this.ifQuiz = true;
        },
        // 提交问题
        submit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    let params = {
                        question: this.formInline.question,
                        siteCode: this.$store.state.siteCode
                    }
                    sitequestionanswerSave(params).then((res) => {
                        if (res && res.code == 0) {
                            this.$refs['ruleForm'].resetFields();
                            this.formInline = {}
                            Message.success({ message: this.getLanguageText(this.languageType, '提交成功') })
                            this.ifQuiz = false;
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cancel() {
            this.formInline = {}
            this.$refs['ruleForm'].resetFields();
            this.ifQuiz = false;
        }
    },
    watch: {
        node: function (val) {
            this.getData()
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 980px) {
    .qa {
        width: 90vw !important;
        .label {
            font-size: 3.5vw !important;
        }
        .content {
            font-size: 2.5vw !important;
        }
    }
    .bt_r {
        font-size: 2.5vw;
    }
    .el-textarea {
        font-size: 2.5vw;
    }
    .asking {
        line-height: 1 !important;
    }
    .answer {
        line-height: 1 !important;
    }
}
.qa {
    width: 1300px;
    padding: 50px 0;
    margin: 0 auto;
    .line {
        width: 100%;
        height: 20px;
        margin-bottom: 20px;
        background-color: #5067A1;
    }
    .asking {
        width: 100%;
        min-height: 40px;
        padding: 10px 20px;
        box-sizing: border-box;
        line-height: 40px;
        border: 1px solid #ccc;
        border-bottom: 0;
    }
    .answer {
        width: 100%;
        min-height: 40px;
        padding: 10px 20px;
        box-sizing: border-box;
        line-height: 1.5;
        border: 1px solid #ccc;
        display: flex;
    }
    .label {
        margin-right: 15px;
        font-size: 30px;
        color: black;
        font-weight: 600;
        font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    }
    .content {
        font-size: 20px;
        color: rgb(88, 88, 88);
        font-weight: 600;
        white-space: pre-line;
        font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    }
}
.phone {
    width: 100%;
    .phone_qa {
        width: 100%;
        padding: 1vh 0;
        margin: 0 auto;
        .line {
            width: 100%;
            height: 2vh;
            margin-bottom: 2vh;
            background-color: #5067A1;
        }
        .asking {
            width: 90%;
            min-height: 4vh;
            padding: 1vh 2vh;
            box-sizing: border-box;
            line-height: 4vh;
            border: 0.1vh solid #ccc;
            border-bottom: 0;
            margin: 0 auto;
        }
        .answer {
            width: 90%;
            min-height: 4vh;
            padding: 1vh 2vh;
            box-sizing: border-box;
            line-height: 1.5;
            border: 1px solid #ccc;
            display: flex;
            margin: 0 auto;
        }
        .label {
            margin-right: 1.5vh;
            font-size: 3vh;
            color: black;
            font-weight: 600;
            font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
        }
        .content {
            font-size: 2vh;
            color: rgb(88, 88, 88);
            font-weight: 600;
            white-space: pre-line;
            font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
        }
    }
    .phone_form {
        width: 90%;
        margin: 0 auto;
        .phone_form_button {
            display: flex;
            flex-direction: row-reverse;
        }
    }
}
</style>
<style lang="scss">
    .vanForm_question {
        margin: 1vh 0;
        .van-cell {
            font-size: 3vh;
            line-height: normal;
            border: 1px solid #ccccccad;
            padding: 0.5vh;
        }
    }
</style>